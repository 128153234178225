<template>
<div class="goodsList">
   <div class="goodsList-header">
          <div>
              <div class="title">技术产品</div>
              <div class="info">低成本、高品质、高智能、高性能、广部署</div>
          </div>
   </div>
   <div class="goodsList-content">
          <div class="title">技术产品支持</div>
          <div class="goods-list-box">
              <div class="left">
                    <div class="list-item"
                         v-for="(item,index) in goodsList"
                         :key="index"
                    >
                       <div class="list-item-box">
                         <div class="title"
                              @click="itemClick(index)"
                              :class="itemActive==index?'active':''"
                         >{{item.label}}</div>
                         <img
                             :class="itemActive==index?'rotating':''"
                             width="26px" height="26px" src="../../assets/blank-right.png">
                       </div>
                       <div class="item-list"
                            v-show="itemActive==index"
                            :class="itemActive==index?'active':''"
                            v-if="item.children">
                          <div class="item-children"
                               :class="childrenActive==index1?'active':''"
                               v-for="(item1,index1) in  item.children"
                               @click="childrenClick(index1)"
                               :key="index1"
                          >
                              {{item1.label}}
                          </div>
                       </div>

                    </div>
              </div>
              <div class="right" v-if="content">
                      <div class="title">{{title}}</div>
                      <div class="right-content" v-if="content.constructor==Array">
                        <div class="right-item"
                             v-for="(item,index) in content"
                             :key="index"
                             @click="goDetail(item)"
                        >
                          <div class="info">
                                  <div class="title">{{item.productTitle?item.productTitle:''}}</div>
                                  <div class="name">{{item.label}}</div>
                                  <div class="text">{{item.productDesc?item.productDesc:''}}</div>
                                  <div class="more">了解更多></div>
                          </div>
                          <div class="img-box">
                            <img :src="item.productPicture" width="300px" height="200px" alt="">

                          </div>
                        </div>
                      </div>
                      <div class="right-content"
                           v-else-if="content.styleType==5"
                      >
                        <div class="right-item"
                             @click="goDetail(content)"
                        >
                          <div class="info style5Info">
                            <div class="title">{{content.productTitle?content.productTitle:''}}</div>
                            <div class="info-list">
                                <div class="info-item"
                                    v-for="(item3,index3) in content.algorithmInfos"
                                     :key="index3"
                                >
                                  <span class="round"></span> {{item3.title}}
                                </div>
                            </div>
                            <div class="more">了解更多></div>
                          </div>
                        </div>
                      </div>
                      <div class="right-content" v-else>
                        <div class="right-item"
                             @click="goDetail(content)"
                        >
                          <div class="info">
                            <div class="title">{{content.productTitle?content.productTitle:''}}</div>
                            <div class="name">{{content.label}}</div>
                            <div class="text">{{content.productDesc?content.productDesc:''}}</div>
                            <div class="more">了解更多></div>
                          </div>
                          <div class="img-box">
                            <img :src="content.productPicture" width="300px" height="200px" alt="">
                          </div>
                        </div>
                      </div>
              </div>
          </div>
   </div>
</div>
</template>

<script>
export default {
  name: "goodsList",
  data(){
    return {
      goodsList:[],
      itemActive:0,
      childrenActive:0,
      content:null,
      title:'',
    }
  },
  created() {
    this.getGoodsList();
  },
  mounted() {

  },
  methods:{
    getGoodsList(){
      this.$get('/website/product/category/treeselect').then((res)=>{
        this.goodsList=res.data;
        this.$nextTick(()=>{
          this.content=this.goodsList[0].children[0].children;
          this.title=this.goodsList[0].children[0].label
        })
      }).catch((err)=>{
        this.msgError(err.msg);
      })
    },
    childrenClick(index){
      this.childrenActive=index;
      if(this.goodsList[this.itemActive].children[index].children){
        this.content=this.goodsList[this.itemActive].children[index].children
        this.title=this.goodsList[this.itemActive].children[index].label
      }else {
        this.content=this.goodsList[this.itemActive].children[index];
        this.title=this.goodsList[this.itemActive].children[this.childrenActive].label
      }
    },
    itemClick(index){
      this.itemActive=index;
      this.childrenActive=0;
      if(this.goodsList[this.itemActive].children[this.childrenActive].children){
        this.content=this.goodsList[this.itemActive].children[this.childrenActive].children
        this.title=this.goodsList[this.itemActive].children[this.childrenActive].label

      }else {
        this.content=this.goodsList[this.itemActive].children[this.childrenActive];
        this.title=this.goodsList[this.itemActive].children[this.childrenActive].label

      }
      console.log(this.content)
    },
    goDetail(item){
      if(item.styleType==1){
        this.$router.push({
          name:'night',
          query:{
            id:item.id
          }
        })
      }
      if(item.styleType==2){
        this.$router.push({
          name:'eyes',
          query:{
            id:item.id
          }
        })
      }
      if(item.styleType==3){
        this.$router.push({
          name:'engine',
          query:{
            id:item.id
          }
        })
      }
      if(item.styleType==4){
        this.$router.push({
          name:'parsing',
          query:{
            id:item.id
          }
        })
      }
      if(item.styleType==5){
        this.$router.push({
          name:'algorithm',
          query:{
            id:item.id
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.goodsList{
  padding-top: 64px;
  .goodsList-header{
    width: 100%;
    height: 507px;
    background-image: url("../../assets/goodsList-bg.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: black;
    position: relative;
    .title{
      font-size: 38px;
    }
    .info{
      margin-top: 20px;
      font-size: 24px;
    }
  }
  .goodsList-content{
    margin-top: 80px;
    padding-bottom: 30px;
    .title{
      font-size: 38px;
      text-align: center;
    }
    .goods-list-box{
      width: 1200px;
      margin: 0 auto;
      margin-top: 80px;
      display: flex;
      justify-content: space-around;
      .left{
        width: 260px;
        .list-item{
          margin-bottom: 20px;
          .list-item-box{
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .title{
              font-size: 22px;
              color: #797979;
              cursor: pointer;
          }
          .title.active{
            color: black;
          }
          .rotating{
              transition: all 0.5s;
              transform: rotate(90deg);
          }
        }
        .item-list{
          max-height: 0px;
          overflow: hidden;
          opacity: 0;
          transition: all 2s;
        }
        .item-list.active{
          opacity: 1;
          transition: all 2s;
          max-height: 400px;
          overflow-y: auto;
        }
        .item-children{
          padding-left: 30px;
          margin-top: 10px;
          cursor: pointer;
          font-size: 18px;
          color: #797979;
        }
        .item-children.active{
              color: black;
        }
      }
      .right{
        width: 800px;
        >.title{
          font-size: 22px;
          font-weight: bold;
          text-align: left;
        }
        .right-content{
          .right-item{
            padding: 30px 80px;
            background: #F2F2F2;
            height: 300px;
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            .info{
              .title{
                font-size: 20px;
                text-align: left;
              }
              .name{
                font-size: 20px;
                font-weight: bold;
              }
              .text{
                font-size: 14px;
                margin-top: 20px;
              }
              .more{
                margin-top: 50px;
              }
            }
            .style5Info{
                width: 100%;
            }
            .info-list{
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;
              font-weight: bold;
              .info-item{
                width: 32%;
                margin-right: 2%;
                margin-top: 20px;
                .round{
                  display: inline-block;
                  width: 5px;
                  height: 5px;
                  border-radius: 50%;
                  background: black;
                  margin-right: 10px;
                  vertical-align: middle;
                }
              }
              .info-item:nth-child(3n){
                margin-right: 0;
              }
            }
          }

        }
      }
    }
  }
}
</style>
